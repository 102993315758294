@import "breakpoints";
@import "fonts";
@import "palette";
@import "~rfs/scss";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

/* prettier-ignore */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
-webkit-font-smoothing: antialiased;
}
/* HTML5 display-role reset for older browsers */
/* prettier-ignore */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

html,
body {
	font-size: 16px;
}

body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/**
  ****************
  * END CSS RESET
  ****************
**/

$min-width: 320px;
$max-width: 1200px;
$baseline: 1.25rem;
$minFont: 1.25rem;
$maxFont: 4.875rem;
$minScreen: 18.75rem;
$maxScreen: 75rem;

$h1: 78px;
$h2: 48px;
$h3: 1.5rem;

html,
body {
	background-color: $off-white;
	font-family: $font-lato;
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-noto;
}

h1 {
	@include font-size($h1);
}
h2 {
	@include font-size($h2);
}
h3 {
	@include font-size($h3);
}

p,
span {
	@include font-size(16px);
	line-height: 28px;
	margin-bottom: 20px;
}

span {
	line-height: unset;
	margin-bottom: unset;
}

.success {
	color: $green;
	@include font-size(24px);
}

.container {
	margin: 0 auto;
	max-width: 100%;

	@media (min-width: $small-device) {
		max-width: 540px;
	}
	@media (min-width: $medium-device) {
		max-width: 720px;
	}
	@media (min-width: $large-device) {
		max-width: 960px;
	}
	@media (min-width: $xl-device) {
		max-width: 1140px;
	}
	@media (min-width: $xxl-device) {
		max-width: 1320px;
	}
}

.header {
	grid-area: header;
}

footer {
	grid-area: footer;
}

main {
	grid-area: main;
}

@media (min-width: $medium-device) {
	h2 {
		font-size: 2rem;
	}
}

.footnote {
	text-align: right;
}

@media (min-width: $medium-device) {
	.footnote h3 {
		font-size: 0.5rem;
	}
}

.adaptive-page {
	background-image: url("/img/adaptive-bg.png");
	background-color: #f4f4f5;
	background-position: 0;
	background-size: cover;

	.logo-sections {
		img {
			filter: grayscale(100%);
		}
	}

	main {
		h1,
		span {
			@include font-size(56px);
		}

		@media (min-width: $large-device) {
			h1,
			span {
				font-size: 2.5rem;
			}
		}

		p {
			@include font-size(24px);
		}
	}
}
